import { useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

export const VideoComponent = ({ videoFile, className = "" }) => {
  const videoRef = useRef(null);

  const playAnimation = () => {
    videoRef?.current?.play();
  };

  //   const stopAnimation = () => {
  //     videoRef.current.stop();
  //   };

  //   const pauseAnimation = () => {
  //     videoRef.current.pause();
  //   };

  const goToAndStop = (value) => {
    // videoRef?.current?.pause();
  };

  const { ref, inView } = useInView({
    triggerOnce: false, // Set to true to play the animation only once
    threshold: 0.5, // Adjust the threshold as needed (0 to 1)
  });

  useEffect(() => {
    if (inView && videoRef) {
      playAnimation();
    } else if (videoRef) {
      goToAndStop(0);
    }
  }, [inView]);

  return (
    <div ref={ref}>
      <video
        className={className}
        src={videoFile}
        loop={false}
        ref={videoRef}
        autoPlay={false}
        muted="muted"
        controls={false}
      />
    </div>
  );
};
