import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";

// interface CardData {
//   id: number;
//   title: string;
//   content: React.ReactNode;
// }

const AnimatedCards = ({ cardsData = [] }) => {
  const [cards, setCards] = useState(cardsData);

  const moveToTop = (clickedId) => {
    const newOrders = [
      cards.find((card) => card.id === clickedId),

      ...cards.filter((card) => card.id !== clickedId),
    ];
    setCards(newOrders);
  };

  //   useEffect(() => {
  //     setCards(cardsData);
  //   }, [cardsData]);

  return (
    <div className="sm:p-4 relative h-[600px] sm:w-[1000px] w-[90vw] mx-auto mt-4 ">
      {cards.map((card, index) => (
        <div
          key={card.id}
          className={`
            absolute w-full cursor-pointer
            transition-all duration-300 ease-in-out
            hover:z-50 hover:scale-[1.02]

            sm:top-[${index * 60}px] top-[${index * 40}px]
            overflow-hidden
          `}
          // onClick={() => moveToTop(card.id)}
        >
          <div className="h-full transition-all duration-300 ease-in-out hover:h-auto">
            <div className="transition-all duration-300 ease-in-out">
              {card.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AnimatedCards;
