import { useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import Lottie from "lottie-react";

export const LottieComponent = ({ animationData, className = "" }) => {
  const lottieRef = useRef(null);

  const playAnimation = () => {
    lottieRef.current.play();
  };

  const stopAnimation = () => {
    lottieRef.current.stop();
  };

  const pauseAnimation = () => {
    lottieRef.current.pause();
  };

  const goToAndStop = (value) => {
    lottieRef.current.goToAndStop(value);
  };

  const { ref, inView } = useInView({
    triggerOnce: false, // Set to true to play the animation only once
    threshold: 0.5, // Adjust the threshold as needed (0 to 1)
  });

  useEffect(() => {
    if (inView) {
      playAnimation();
    } else {
      goToAndStop(0);
    }
  }, [inView]);

  return (
    <div ref={ref}>
      <Lottie
        className="max-w-[1200px]"
        lottieRef={lottieRef}
        animationData={animationData}
        loop={false}
        autoPlay={false}
      />
    </div>
  );
};
